<template>
  <div></div>
</template>

<script>
export default {
  name: "Training",
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
}
</script>

<style scoped>

</style>